<template>
  <div
    :class="$style.root"
    @mouseenter="mouseentered = true"
    @mouseleave="mouseentered = false"
  >
    <div :class="$style.symbol" />
  </div>
</template>

<style module>
.root {
  filter: drop-shadow(var(--map--pill--box-shadow));
}

.symbol {
  width: calc(var(--lh) * .6);
  height: calc(var(--lh) * .5);

  background-color: var(--pill--background-color);
  transition: background-color var(--transition--default);

  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
</style>
