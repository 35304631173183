<template>
  <div :class="$style.root">
    <div :class="$style.item">
      <MapIconVenue :class="$style.icon" />
      <div :class="$style.label">
        {{ $t('label.venue') }}
      </div>
    </div>
    <div :class="$style.item">
      <MapIconEventLocation :class="$style.icon" />
      <div :class="$style.label">
        {{ $t('label.event') }}
      </div>
    </div>
    <div :class="$style.item">
      <MapIconFocusWeek :class="$style.icon" />
      <div :class="$style.label">
        {{ $t('label.focusWeek') }}
      </div>
    </div>
    <div :class="$style.item">
      <MapIconRoute :class="$style.icon" />
      <div :class="$style.label">
        {{ $t('label.route') }}
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
.root {
  position: absolute;
  z-index: calc(var(--z-index--nav) - 1);
  bottom: var(--unit--vertical);
  left: var(--unit--horizontal);

  --padding: calc(var(--unit--spacer) * 2);
  padding: var(--padding);

  max-width: var(--nav--max-width);
  border-radius: var(--border-radius--card);

  background-color: var(--color--white);

  display: flex;
  gap: var(--unit--spacer);

  @media (max-width: $breakpoint-s) {
    display: none;
  }
}

.item {
  composes: font-size-x-small from global;
  display: flex;
  align-items: baseline;
  gap: .65ch;
  overflow: hidden;
}

.label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.icon {
  filter: none;
  --pill--background-color: var(--color--gray);
}
</style>
