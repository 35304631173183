export const mapFilteredMapVenueEntries = (entries = [], filterCategoryIds = [], filterCityIds = [], filterVenueIds = [], filterFocusWeekIds = []) => {
  return entries
    ?.map((i) => { return { mapType: 'venue', ...i } })
    ?.filter(entry => entry?.attributes?.lat && entry?.attributes?.long)
    ?.filter(entry => filterCityIds?.length ? filterCityIds?.includes(String(entry.attributes?.city_entry?.data?.id)) : true)
    ?.filter(entry => filterVenueIds?.length ? filterVenueIds?.includes(String(entry.id)) : true)
    ?.filter(entry => {
      if ( filterCategoryIds?.length ) {
        const ids = entry?.attributes?.event_entries?.data?.map(i => toRaw(i?.attributes?.event_categories?.data))?.flat()?.map(i => i?.id)?.filter(Boolean)
        return ids?.some(i => filterCategoryIds?.includes(String(i)))
      } else {
        return true
      }
    })
    ?.filter(entry => {
      if (filterFocusWeekIds?.length) {
        const ids = entry?.attributes?.event_entries?.data?.map(i => i?.attributes?.focus_week_entry?.data?.id)?.filter(Boolean)
        return ids?.some(i => filterFocusWeekIds?.includes(String(i)))
      } else {
        return true
      }
    })
    || []
}
