import qs from 'qs'

const fields = [
  'background_color',
  'long',
  'lat'
]

export const queryClusterEntryMapThumbnails = (options: QueryManyOptions) => {
  const sortKey = strapiGetI18nSortKey(options?.locale, 'titles.i18n_string')

  const query = qs.stringify(
    {
      publicationState: options?.publicationState || 'live',
      sort: [`${sortKey}:asc`],
      pagination: {
        limit: 100
      },
      fields,
      populate: {
        ...queryFragmentCollectionTypeDefault,
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `cluster-entries?${query}`
}
