export const mapFilteredFocusWeekDataEntries = (entries = [], filterCategoryIds = [], filterCityIds = [], filterVenueIds = [], filterFocusWeekIds = []) => {
  return entries
    ?.filter(i => i?.attributes?.long)?.map( (i) => { return { mapType: 'focus-week', ...i } })
    ?.filter(entry => filterCityIds?.length ? filterCityIds?.includes(String(entry.attributes?.city_entry?.data?.id)) : true)
    ?.filter(entry => {
      if ( filterVenueIds?.length ) {
        const ids = entry?.attributes?.event_entries?.data?.map(i => i?.attributes?.venue_entry?.data?.id)?.filter(Boolean)
        return ids?.some(i => filterVenueIds?.includes(String(i)))
      } else {
        return true
      }
    })
    ?.filter(entry => {
      if ( filterCategoryIds?.length ) {
        const ids = toRaw(entry?.attributes?.event_entries?.data?.map(i => i?.attributes?.event_categories?.data?.map(i => i?.id)?.flat())?.flat())?.filter(Boolean)
        return ids?.some(i => filterCategoryIds?.includes(String(i)))
      } else {
        return true
      }
    })
    || []
}
