import qs from 'qs'

import { eventsQueryCurrentAndUpcomingFilterParams } from '~/composables/events/filter-date-objects'

export const queryFocusWeekMapThumbnails = (options: QueryManyOptions) => {
  const sortKey = strapiGetI18nSortKey(options?.locale, 'titles.i18n_string')

  const query = qs.stringify(
    {
      publicationState: options?.publicationState || 'live',
      sort: options?.sort || ['date_start:desc', 'date_end:desc', `${sortKey}:asc`],
      pagination: {
        limit: 100
      },
      filters: options?.filters || [],
      populate: {
        ...queryFragmentCollectionTypeDefault,
        city_entry: {
          populate: {
            ...queryFragmentCollectionTypeDefault,
            cluster_entry: {
              fields: ['background_color'],
              populate: {
                ...queryFragmentCollectionTypeDefault,
              }
            },
          }
        },
        event_entries: {
          sort: options?.sort || ['date_time_start:asc', 'date_time_end:asc', `${sortKey}:asc`],
          filters: eventsQueryCurrentAndUpcomingFilterParams(),
          populate: {
            ...queryFragmentCollectionTypeDefault,
            cover_image: true,
          }
        },
        cover_image: true,
        address: true,
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `focus-week-entries?${query}`
}
