<template>
  <ul :class="$style.root">
    <li v-if="lat && long && address">
      <div :class="$style.label">
        {{ $t('map.label.address') }}
      </div>
      <a
        :href="`https://www.google.com/maps/search/?api=1&query=${lat},${long}`"
        target="_blank"
        :class="$style.linkToDirection"
      >
        {{ address }}
      </a>
    </li>

    <li v-if="openingTimes?.length">
      <div :class="$style.label">
        {{ $t('map.label.openingTimes') }}
      </div>
      <SharedOpeningTimesComponent
        :openingTimes="openingTimes"
      />
    </li>

    <li v-if="accessibilityTypesString">
      <div :class="$style.label">
        {{ $t('map.label.accessibility') }}
      </div>
      <div>
        {{ accessibilityTypesString }}
      </div>
    </li>

    <li>
      <div :class="$style.label">
        {{ $t('map.label.tickets') }}
      </div>
      <a
        :href="ticketUrl"
        target="_blank"
        :class="$style.linkToTickets"
        v-if="ticketUrl"
      >
        {{ $t('map.label.buyTickets') }}
      </a>
      <span v-else>
        {{ $t('map.label.noTicket') }}
      </span>
    </li>
  </ul>
</template>

<script setup>
const props = defineProps({
  address: {
    type: String,
    required: false
  },
  lat: {
    type: Number,
    required: false
  },
  long: {
    type: Number,
    required: false
  },
  accessibilityTypes: {
    type: Array,
    required: false
  },
  ticketUrl: {
    type: Object,
    required: false
  },
  openingTimes: {
    type: Object,
    required: false
  }
})

const accessibilityTypesString = computed(() => {
  return props?.accessibilityTypes?.map((type) => {
    return i18nGetLocalizedString(type?.attributes?.titles)
  })?.filter(Boolean)?.join(', ')
})

const ticketUrl = computed(() => i18nGetLocalizedString(props?.ticketUrl))
</script>

<style module>
.root {
  composes: reset-list from global;

  display: flex;
  flex-direction: column;
  gap: var(--unit--vertical);
}

.label {
  composes: font-size-xx-small from global;
  text-transform: uppercase;
  color: var(--color--gray--mid);
}

.linkToDirection {
  composes: reset-link link-underline from global;
}
</style>
