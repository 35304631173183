<template>
  <MapExploreFiltersLayoutComponent>
    <template #label>
      {{ $t('map.label.explore.label') }}
    </template>
    <template #content="{closeSelfIfNotDesktop}">
      <MapExploreSegmentComponent
        :data="clustersSegment"
        value="1"
        type="clusters"
        :flyTo="flyTo"
        :zoom="mapStore?.zoom?.cluster"
        :resetFilters="resetFilters"
        :closeSelfIfNotDesktop="closeSelfIfNotDesktop"
      />

      <MapExploreSegmentComponent
        :data="citiesSegment"
        value="2"
        type="cities"
        :flyTo="flyTo"
        :zoom="mapStore?.zoom?.city"
        :resetFilters="resetFilters"
        :closeSelfIfNotDesktop="closeSelfIfNotDesktop"
      />

      <MapExploreSegmentComponent
        :data="venuesSegment"
        value="3"
        type="venues"
        :flyTo="flyTo"
        :zoom="mapStore?.zoom?.venue"
        :resetFilters="resetFilters"
        :closeSelfIfNotDesktop="closeSelfIfNotDesktop"
      />

      <MapExploreRoutesComponent
        v-if="groupedRoutes"
        v-for="(data, index) in groupedRoutes"
        :key="data?.id"
        :value="2 + (index + 1)"
        :data="data"
        :flyTo="flyTo"
        :resetFilters="resetFilters"
        :closeSelfIfNotDesktop="closeSelfIfNotDesktop"
      />
    </template>
  </MapExploreFiltersLayoutComponent>
</template>

<script setup>
import { omit, groupBy } from 'lodash-es'

const mapStore = useMapStore()
const nuxtApp = useNuxtApp()
const route = useRoute()
const router = useRouter()

const { $i18n } = nuxtApp

const props = defineProps({
  clusterEntries: {
    type: Array
  },
  cityEntries: {
    type: Array
  },
  venueEntries: {
    type: Array
  },
  routeEntries: {
    type: Array
  },
  flyTo: {
    type: Function,
    required: true
  },
})

const clustersSegment = computed(() => {
  return {
    label: $i18n.t('label.cluster', { count: 2 }),
    entries: props?.clusterEntries?.map((entry) => {
      return {
        ...entry?.attributes,
        label: i18nGetLocalizedTitle(i18nGetLocalizedData(entry?.attributes)),
        value: entry?.id,

      }
    })
  }
})

const citiesSegment = computed(() => {
  return {
    label: $i18n.t('label.city', { count: 2 }),
    entries: props?.cityEntries?.map((entry) => {
      return {
        ...entry?.attributes,
        label: i18nGetLocalizedTitle(i18nGetLocalizedData(entry?.attributes)),
        value: entry?.id,

      }
    })
  }
})

const venuesSegment = computed(() => {
  return {
    label: $i18n.t('label.venue', { count: 2 }),
    entries: props?.venueEntries?.map((entry) => {
      return {
        ...entry?.attributes,
        label: i18nGetLocalizedTitle(i18nGetLocalizedData(entry?.attributes)),
        value: entry?.id,

      }
    })
  }
})

const groupedRoutes = computed(() => {
  return groupBy(props?.routeEntries, (i) => i?.attributes?.map_route_category?.data?.id)
})

const resetFilters = () => {
  router.push({ query: { ...omit(route.query, ['cities', 'clusters', 'venues', 'categories', 'focus-weeks']) } })
}
</script>
