<template>
  <div :class="$style.root">
    <div
      :class="$style.label"
    >
      <button :class="$style.labelButton" @click="minimize = !minimize">
        <slot name="label" />
      </button>
      <slot name="button" />

      <button
        :class="$style.minimizeButton"
        @click="minimize = !minimize"
      >
        <SvgoMinus v-if="!minimize" />
        <SvgoPlus v-else />
      </button>
    </div>

    <ConfigProvider
      :use-id="useIdFunction"
      v-if="!minimize"
    >
      <AccordionRoot
        :class="$style.accordion"
        type="single"
        :collapsible="true"
        @update:modelValue="updateModelValue"
      >
        <slot name="content" :closeSelfIfNotDesktop="closeSelfIfNotDesktop" />
      </AccordionRoot>
    </ConfigProvider>
  </div>
</template>

<script setup>
const useIdFunction = () => useId()
const route = useRoute()

const { isDesktop } = useDevice();
const activeId = ref(null)
const minimize = ref(!isDesktop)

const updateModelValue = (value) => {
  activeId.value = value
}

const closeSelfIfNotDesktop = () => {
  if (!isDesktop ) {
    minimize.value = true
  }
}
</script>

<style module>
.root {
  composes: reset-scrollbar from global;

  background-color: var(--color--white);

  padding: var(--unit--vertical) var(--unit--horizontal);
  border-radius: var(--border-radius--card);

  box-shadow: 0 0 var(--padding) rgba(0,0,0,.15);

  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  gap: var(--unit--spacer);
}

.label {
  composes: font-size-x-small from global;
  color: var(--color--blue);

  text-transform: upper;

  display: flex;
  justify-content: space-between;
  gap: var(--unit--spacer);
  align-items: baseline;
}

.labelButton {
  composes: reset-button from global;
}

.minimizeButton {
  composes: reset-button from global;

  color: var(--color--gray);
  width: var(--unit--spacer);
}

.label:has(.labelButton:hover) .minimizeButton,
.minimizeButton:hover {
  color: var(--color--blue);
}

.minimizeButton svg {
  width: 100;
  stroke: currentColor;
  stroke-width: var(--stroke--default);
}

.resetButton {
  composes: reset-button button-round from global;
  --button-round--stroke: var(--stroke--default);
  color: var(--color--gray--mid) !important;
}

.resetButton:hover {
  color: var(--color--gray) !important;
}

.accordion {
  display: flex;
  flex-direction: column;
  gap: var(--unit--spacer);
  margin-top: var(--unit--spacer);
}
</style>
