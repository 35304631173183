<template>
  <div :class=$style.root>
    <div :class="$style.header">
      <span :class=$style.label>
        {{ label }}
      </span>
      <slot />
    </div>

    <div :class=$style.title>
      {{ title }}
    </div>

    <ul :class="$style.list">
      <li
        v-if="cluster"
      >
        <div :class="$style.label">
          {{ $t('label.cluster') }}
        </div>
        <I18nLinkWithFallback
          class="link-underline"
          name="clusters-slug"
          :localized-data="cluster"
          type="dynamic"
        >
          {{ clusterTitle }}
        </I18nLinkWithFallback>
      </li>

      <li
        v-if="city"
      >
        <div :class="$style.label">
          {{ $t('label.city') }}
        </div>
        <I18nLinkWithFallback
          class="link-underline"
          name="cities-slug"
          :localized-data="city"
          type="dynamic"
        >
          {{ cityTitle }}
        </I18nLinkWithFallback>
      </li>

      <li
        v-if="venues?.length"
      >
        <div :class="$style.label">
          {{ $t('label.venue') }}
        </div>

        <ul :class="$style.venuesList">
          <li
            v-for="venue in venues"
            :key="venue.id"
          >
            <I18nLinkWithFallback
              class="link-underline"
              name="venues-slug"
              :localized-data="venue.attributes"
              type="dynamic"
            >
              {{ getLocalizedTitle(venue.attributes) }}
            </I18nLinkWithFallback>
          </li>
        </ul>
      </li>

      <li
        v-for="section in localizedData?.sections"
        :key="section?.id"
      >
        <div :class="$style.label">
          {{ i18nGetLocalizedLabel(section) }}
        </div>
        <CoreTextComponent
          v-if="i18nGetLocalizedDescription(section)"
          :string="i18nGetLocalizedDescription(section)"
        />
      </li>
    </ul>
  </div>
</template>

<script setup>
const props = defineProps({
  entry: {
    type: Object
  }
})

const getLocalizedTitle = i18nGetLocalizedTitle
const i18nGetLocalizedLabel = (data) => i18nGetLocalizedString(data?.labels)
const i18nGetLocalizedDescription = (data) => i18nGetLocalizedString(data?.descriptions, 'i18n_richtext_en')

const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.entry))
})

const label = computed(() => {
  return i18nGetLocalizedTitle(localizedData?.value?.map_route_category?.data?.attributes)
})

const title = computed(() => i18nGetLocalizedTitle(localizedData?.value))

const cluster = computed(() => {
  return localizedData?.value?.city_entry?.data?.attributes?.cluster_entry?.data?.attributes
})

const clusterTitle = computed(() => {
  return i18nGetLocalizedTitle(cluster.value)
})

const city = computed(() => {
  return localizedData?.value?.city_entry?.data?.attributes
})

const cityTitle = computed(() => {
  return i18nGetLocalizedTitle(city.value)
})

const venues = computed(() => {
  return localizedData?.value?.venue_entries?.data
})
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.label {
  composes: font-size-xx-small from global;
  text-transform: uppercase;
  color: var(--color--gray--mid);
}

.title {
  composes: font-size-small from global;
  margin-bottom: calc(var(--unit--vertical) - (var(--lh) - var(--fs)));
}

.list {
  composes: reset-list from global;
  display: flex;
  flex-direction: column;
  gap: var(--unit--spacer);
}

.venuesList {
  composes: reset-list from global;
  display: flex;
  flex-direction: column;
}

.link {
  margin-top: calc(var(--unit--vertical) - (var(--lh) - var(--fs)));

  --pill--border-color--hover: none;
  --pill--border-color: none;
}
</style>
