import qs from 'qs'

import { eventsQueryCurrentAndUpcomingFilterParams } from '~/composables/events/filter-date-objects'

const fields = [
  'long',
  'lat'
]

export const queryVenueEntryMapThumbnails = (options: QueryManyOptions) => {
  const sortKey = strapiGetI18nSortKey(options?.locale, 'titles.i18n_string')

  const query = qs.stringify(
    {
      publicationState: options?.publicationState || 'live',
      sort: [`${sortKey}:asc`],
      pagination: {
        limit: 100
      },
      filters: {
        $and: [
          {
            long: { $notNull: true }
          },
          {
            lat: { $notNull: true }
          }
        ]
      },
      fields,
      populate: {
        ...queryFragmentCollectionTypeDefault,
        city_entry: {
          populate: {
            ...queryFragmentCollectionTypeDefault,
            cluster_entry: {
              fields: ['background_color'],
              populate: {
                ...queryFragmentCollectionTypeDefault,
              }
            },
          }
        },
        address: true,
        cover_image: true,
        accessibility_types: {
          populate: queryFragmentCollectionTypeDefault
        },
        opening_times: true,
        ...queryFragmentParticipantEntries,
        event_entries: {
          sort: options?.sort || ['date_time_start:asc', 'date_time_end:asc', `${sortKey}:asc`],
          filters: {
            $and: [
              eventsQueryCurrentAndUpcomingFilterParams(),
              {
                $or: [
                  { permanent_event: { $eq: true } },
                ]
              }
            ]
          },
          populate: {
            ...queryFragmentCollectionTypeDefault,
            cover_image: true,
          }
        },
        ticket_url: true,
        participant_entries: {
          populate: queryFragmentCollectionTypeDefault
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `venue-entries?${query}`
}
