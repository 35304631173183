<template>
  <MapboxLayer
    v-if="entry && entry?.attributes?.geojson"
    :layer="{
      'id': `map-geojson-layer-${index}`,
      'type': 'fill',
      'source': `map-geojson-source-${index}`,
      'paint': {
        'fill-color': fillColor,
        'fill-opacity': hidden ? 0 : .15,
      }
    }"
  />
  <MapboxLayer
    v-if="entry && entry?.attributes?.geojson"
    :layer="{
      'id': `map-geojson-line-${index}`,
      'type': 'line',
      'source': `map-geojson-source-${index}`,
      'layout': {
          'line-join': 'round',
          'line-cap': 'round',
      },
      'paint': {
          'line-color': fillColor,
          'line-width': 1,
          'line-opacity': hidden ? 0 : 1,
      }
    }"
  />
</template>

<script setup>
const props = defineProps({
  entry: {
    type: Object
  },
  index: {
    type: Number
  },
  hidden: {
    type: Boolean,
    default: false
  }
})

const fillColor = computed(() => {
  return props?.entry?.attributes?.cluster_entry?.data?.attributes?.background_color || '#2487B0'
})

const emit = defineEmits(['removeLayer'])
onBeforeUnmount(() => {
  emit('removeLayer', `map-geojson-layer-${props?.index}`)
  emit('removeLayer', `map-geojson-line-${props?.index}`)
})
</script>
