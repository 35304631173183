import qs from 'qs'

const fields = [
  'long', 'lat', 'geojson', 'slug'
]

export const queryCityEntryMapThumbnails = (options: QueryManyOptions) => {
  const sortKey = strapiGetI18nSortKey(options?.locale, 'titles.i18n_string')

  const query = qs.stringify(
    {
      publicationState: options?.publicationState || 'live',
      sort: [`${sortKey}:asc`],
      pagination: {
        limit: 100
      },
      filters: {
        $and: [
          {
            long: { $notNull: true }
          },
          {
            lat: { $notNull: true }
          }
        ]
      },
      fields,
      populate: {
        ...queryFragmentCollectionTypeDefault,
        cluster_entry: {
          fields: ['background_color'],
        },

      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `city-entries?${query}`
}
