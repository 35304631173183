<template>
  <MapExploreFiltersLayoutSegmentComponent>
    <template #header>
      {{ data?.label }}
    </template>

    <template #content="{closeSelfIfNotDesktop}">
      <ul :class="$style.list">
        <li
          v-for="item in data?.entries"
          :key="item.value"
          :class="$style.item"
        >
          <LayoutPillComponent
            sizeOption="tiny"
            @click="[flyTo(item?.long, item?.lat, zoom), resetFilters(), closeSelfIfNotDesktop()]"
            :style="getBackgroundColor(item) ? `--pill--background-color: ${getBackgroundColor(item)}; --pill--border--base: none;` : null"
            :toUpperCase="false"
          >
            {{ getLabel(item?.label) }}
          </LayoutPillComponent>
        </li>
      </ul>
    </template>
  </MapExploreFiltersLayoutSegmentComponent>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object
  },
  type: String,
  flyTo: {
    type: Function,
    required: true
  },
  zoom: {
    type: Number,
    default: 13
  },
  resetFilters: Function
})

const getBackgroundColor = (entry) => {
  return entry?.city_entry?.data?.attributes?.cluster_entry?.data?.attributes?.background_color || entry?.cluster_entry?.data?.attributes?.background_color || entry?.background_color || 'var(--color--blue)'
}

const getLabel = (label) => baseTruncateString(label, 4)
</script>

<style module>
.list {
  composes: reset-list from global;
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--unit--spacer) / 2);
  margin-bottom: var(--unit--spacer);
}

.item {
  display: flex;
}
</style>
