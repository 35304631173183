<template>
  <I18nLinkWithFallback
    name="events-slug"
    :localizedData="localizedData"
    :class="$style.root"
    type="dynamic"
  >
    <LayoutCoverImageComponent
      v-if="localizedData?.cover_image?.data?.attributes"
      :image="localizedData?.cover_image?.data?.attributes"
      :showCaption=false
      :class="$style.image"
    />

    <div :class="$style.title">
      {{ title }}
    </div>

    <CoreDateTimeRangeComponent
      v-if="localizedData?.date_time_start"
      :dateStart="localizedData?.date_time_start"
      :dateEnd="localizedData?.date_time_end"
      :class="$style.dateRange"
    />
  </I18nLinkWithFallback>

</template>

<script setup>
const props = defineProps({
  data: {
    type: Object
  }
})

const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.data))
})

const title = computed(() => baseTruncateString(i18nGetLocalizedTitle(localizedData?.value), 5) )
</script>

<style module>
.root {
  composes: reset-link from global;
  width: calc(var(--max-width) - var(--unit--horizontal) * 3.5);
  padding-left: var(--unit--horizontal);
  flex-shrink: 0;
}

.root:last-of-type {
  margin-right: var(--unit--horizontal);
}

.title {
  text-decoration-line: underline
}

.root:hover .title {
  text-decoration-line: none;
}

.image {
  width: 100%;
  margin-bottom: var(--unit--spacer);
  --layout--cover-image--border-radius: var(--border-radius--small);

  --base--image--aspect-ratio: 16 / 10;
  --base--image--object-fit: cover;
}

.dateRange {
  composes: font-size-xx-small from global;
  color: var(--color--gray--mid);
  margin-right: auto;
}
</style>
