<template>
  <div :class=$style.root>
    <div :class=$style.title>
      <div class="font-size-small">{{ title }}</div>

      <CoreDateTimeRangeComponent
        v-if="localizedData?.date_time_start"
        :dateStart="localizedData?.date_time_start"
        :dateEnd="localizedData?.date_time_end"
        :class="$style.dateRange"
      />
    </div>

    <LayoutCoverImageComponent
      v-if="localizedData?.cover_image?.data?.attributes"
      :image="localizedData?.cover_image?.data?.attributes"
      :showCaption=false
      :class="$style.image"
    />

    <MapMarkerWithPopupVisitingInformationComponent
      :address="address"
      :long="localizedData?.long"
      :lat="localizedData?.lat"
      :accessibilityTypes="localizedData?.accessibility_types?.data"
      :openingTimes="localizedData?.opening_times"
    />

    <LayoutPillComponent
      v-if="localizedData"
      :localizedData="localizedData"
      componentType="linkWithFallback"
      linkType="dynamic"
      name="events-slug"
      sizeOption="tiny"
      :class="$style.link"
    >
      {{ $t('shared.more') }}
    </LayoutPillComponent>
  </div>
</template>

<script setup>
const props = defineProps({
  entry: {
    type: Object
  }
})

const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.entry))
})

const title = computed(() => i18nGetLocalizedString(localizedData?.value?.titles))

const address = computed(() => i18nGetLocalizedString(localizedData?.value?.address))
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.label {
  composes: font-size-xx-small from global;
  text-transform: uppercase;
  color: var(--color--gray--mid);
}

.title {
  margin-bottom: calc(var(--unit--vertical) - (var(--lh) - var(--fs)));

  display: flex;
  flex-direction: column;
}

.dateRange {
  composes: font-size-x-small from global;
  margin-right: auto;
}

.image {
  width: 100%;
  margin-bottom: var(--unit--vertical);
  --layout--cover-image--border-radius: var(--border-radius--small);
}

.link {
  margin-top: calc(var(--unit--vertical) - (var(--lh) - var(--fs)));

  --pill--border-color--hover: none;
  --pill--border-color: none;
}
</style>
