<template>
  <button
    :class="$style.root"
    :data-type="type"
    :style="[
      backgroundColor ? `--pill--background-color: ${backgroundColor};` : '--pill--background-color: var(--color--blue);'
    ]"
    :key="title"
  >
    <MapIconRoute
      @mouseenter="mouseentered = true"
      @mouseleave="mouseentered = false"
    />

    <LayoutPillComponent
      :class="$style.pill"
      sizeOption="tiny"
      componentType=div
      v-if="showPill || mouseentered && isDesktop"
    >
      {{ title }}
    </LayoutPillComponent>
  </button>
</template>

<script setup>
const { isDesktop } = useDevice();

const props = defineProps({
  title: {
    type: String
  },
  backgroundColor: {
    type: String,
    required: false
  },
  type: {
    type: String,
    required: false
  },
  showPill: {
    type: Boolean,
    required: false
  }
})

const mouseentered = ref(false)
</script>

<style module>
.root {
  composes: reset-button font-default from global;
  color: var(--color--black);

  position: relative;
}

.root:hover {
  --pill--background-color--hover: var(--pill--background-color);
  --pill--color--hover: var(--color--gray--mid);
}

.pill {
  --pill--border-color--hover: none;
  --pill--border-color: none;

  position: absolute;
  top: 50%;
  right: 0;
  transform: translateX(calc( 100% + var(--unit--spacer) / 2)) translateY(-50%);

  box-shadow: var(--map--marker-with-popup--box-shadow);

  white-space: nowrap;
}
</style>
