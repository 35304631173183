<template>
  <button
    :class="$style.root"
    @click="closeMap"
  >
    <SvgoCross data-cross />
  </button>
</template>

<script setup>
const localePath = useLocalePath()
const router = useRouter()
const closeMap = () => {
  if (window.history.state.back === null) {
    router.push(localePath({name: 'index'}))
  } else {
    const route = String(window.history.state.back)?.split('?')[0]
    if (route?.includes('/map')) {
      router.push(localePath({name: 'index'}))
    } else {
      router.back();
    }
  }
}
</script>

<style module>
.root {
  composes: reset-button button-round from global;
  position: absolute;
  top: var(--unit--vertical);
  right: var(--unit--horizontal);
  z-index: var(--z-index--buttonToClose);
}
</style>
