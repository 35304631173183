<template>
  <div :class=$style.root>
    <div :class="$style.header">
      <span :class=$style.label>
        {{ $t(`label.venue`, { count: 1 }) }}
      </span>
      <slot />
    </div>

    <div :class=$style.title>
      {{ title }}
    </div>

    <LayoutCoverImageComponent
      v-if="localizedData?.cover_image?.data?.attributes"
      :image="localizedData?.cover_image?.data?.attributes"
      :showCaption=false
      :class="$style.image"
    />

    <MapMarkerWithPopupVisitingInformationComponent
      :address="address"
      :long="localizedData?.long"
      :lat="localizedData?.lat"
      :accessibilityTypes="localizedData?.accessibility_types?.data"
      :openingTimes="localizedData?.opening_times"
      :ticketUrl="localizedData?.ticket_url"
    />

    <MapMarkerWithPopupVenueEventEntriesComponent
      v-if="permanentEvents?.length"
      :entries="permanentEvents"
      :class="$style.events"
      :label="`${$t('shared.permanent')} ${$t('map.events.label')}`"
    />

    <MapMarkerWithPopupVenueEventEntriesComponent
      v-if="events?.length"
      :entries="events"
      :class="$style.events"
    />

    <ul
      :class="$style.partipants"
      v-if="localizedData?.participant_entries?.data?.length"
    >
      <li :class="$style.label">
        {{ $t(`label.participant`, { count: localizedData?.participant_entries?.data?.length }) }}
      </li>
      <li
        v-for="participant in localizedData?.participant_entries?.data"
        :key="participant?.id"
      >
        <I18nLinkWithFallback
          name="participants-slug"
          type="dynamic"
          class="link-underline"
          :localized-data="{ slug: getParticipantSlug(participant) }"
        >
          {{ getParticipantTitle(participant) }}
        </I18nLinkWithFallback>
      </li>
    </ul>

    <LayoutPillComponent
      :localizedData="entry?.attributes"
      componentType="linkWithFallback"
      linkType="dynamic"
      name="venues-slug"
      sizeOption="tiny"
      :class="$style.link"
    >
      {{ $t('shared.readMore') }}
    </LayoutPillComponent>
  </div>
</template>

<script setup>
const props = defineProps({
  entry: {
    type: Object
  }
})

const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.entry))
})

const title = computed(() => i18nGetLocalizedTitle(localizedData?.value))
const address = computed(() => i18nGetLocalizedString(localizedData?.value?.address))

const events = computed(() => {
  return localizedData?.value?.event_entries?.data?.filter((event) => {
    return event?.attributes?.permanent_event !== true
  })
})

const permanentEvents = computed(() => {
  return localizedData?.value?.event_entries?.data?.filter((event) => {
    return event?.attributes?.permanent_event === true
  })
})

const getParticipantTitle = (participant) => {
  const localizedData = i18nGetLocalizedData(participant?.attributes)
  return i18nGetLocalizedTitle(localizedData)
}

const getParticipantSlug = (participant) => {
  return participant?.attributes?.slug
}

</script>

<style module>
.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.label {
  composes: font-size-xx-small from global;
  text-transform: uppercase;
  color: var(--color--gray--mid);
}

.eventsLabel {
  composes: font-size-xx-small from global;
  padding: var(--padding--button-tiny--top) var(--padding--button-tiny--right) var(--padding--button-tiny--bottom) var(--padding--button-tiny--left);
  border-radius: 9999px;
  backdrop-filter: grayscale(.5);
  display: inline-block;
}

.title {
  composes: font-size-small from global;
  margin-bottom: calc(var(--unit--vertical) - (var(--lh) - var(--fs)));
}

.image {
  width: 100%;
  margin-bottom: var(--unit--vertical);
  --layout--cover-image--border-radius: var(--border-radius--small);
}

.events {
  margin-top: var(--unit--vertical);
}

.link {
  margin-top: calc(var(--unit--vertical) - (var(--lh) - var(--fs)));

  --pill--border-color--hover: none;
  --pill--border-color: none;
}

.partipants {
  composes: reset-list from global;
  margin-top: var(--unit--vertical);
}
</style>
