<template>
  <div :class=$style.root>
    <div :class="$style.header">
      <span :class=$style.label>
        {{ $t(`label.event`, { count: 1 }) }}
      </span>
      <slot />
    </div>

    <div :class="$style.items">
      <MapMarkerWithPopupEventSectionComponent
        v-for="(event, index) in localizedData?.event_entries?.data"
        :key="event?.id"
        :entry="event"
        :class="$style.item"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  entry: {
    type: Object
  }
})

const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.entry))
})
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item:not(:last-of-type) {
  padding-bottom: calc(var(--unit--vertical) * 1);
  margin-bottom: calc(var(--unit--vertical) * 1);
  border-bottom: var(--stroke--default) solid var(--color--gray);
}
</style>
