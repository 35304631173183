<template>
  <div :class=$style.root>
    <div :class="$style.header">
      <span :class=$style.label>
        {{ $t('label.focusWeek') }}
      </span>
      <slot />
    </div>

    <div :class=$style.title>
      <div class="font-size-small">{{ title }}</div>
      <CoreDateTimeRangeComponent
        v-if="localizedData?.date_start"
        :dateStart="localizedData?.date_start"
        :dateEnd="localizedData?.date_end"
        :class="$style.dateRange"
      />
    </div>

    <ul :class="$style.list">
      <li
        v-if="cluster"
      >
        <div :class="$style.label">
          {{ $t('label.cluster') }}
        </div>
        <I18nLinkWithFallback
          class="link-underline"
          name="clusters-slug"
          :localized-data="cluster"
          type="dynamic"
        >
          {{ clusterTitle }}
        </I18nLinkWithFallback>
      </li>

      <li
        v-if="city"
      >
        <div :class="$style.label">
          {{ $t('label.city') }}
        </div>
        <I18nLinkWithFallback
          class="link-underline"
          name="cities-slug"
          :localized-data="city"
          type="dynamic"
        >
          {{ cityTitle }}
        </I18nLinkWithFallback>
      </li>
    </ul>

    <MapMarkerWithPopupVenueEventEntriesComponent
      v-if="localizedData?.event_entries?.data?.length"
      :entries="localizedData?.event_entries?.data"
      :class="$style.events"
    />

    <LayoutPillComponent
      :localizedData="localizedData"
      componentType="linkWithFallback"
      linkType="dynamic"
      name="focus-week-slug"
      sizeOption="tiny"
      :class="$style.link"
    >
      {{ $t('shared.more') }}
    </LayoutPillComponent>
  </div>
</template>

<script setup>
const props = defineProps({
  entry: {
    type: Object
  }
})

const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.entry))
})

const title = computed(() => i18nGetLocalizedTitle(localizedData?.value))

const cluster = computed(() => {
  return localizedData?.value?.city_entry?.data?.attributes?.cluster_entry?.data?.attributes
})

const clusterTitle = computed(() => {
  return i18nGetLocalizedTitle(cluster.value)
})

const city = computed(() => {
  return localizedData?.value?.city_entry?.data?.attributes
})

const cityTitle = computed(() => {
  return i18nGetLocalizedTitle(city.value)
})
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.label {
  composes: font-size-xx-small from global;
  text-transform: uppercase;
  color: var(--color--gray--mid);
}

.title {
  margin-bottom: calc(var(--unit--vertical) - (var(--lh) - var(--fs)));

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.eventsLabel {
  composes: font-size-xx-small from global;
  padding: var(--padding--button-tiny--top) var(--padding--button-tiny--right) var(--padding--button-tiny--bottom) var(--padding--button-tiny--left);
  border-radius: 9999px;
  backdrop-filter: grayscale(.5);
  display: inline-block;
  margin-top: calc(var(--unit--spacer) / 3);
}

.dateRange {
  composes: font-size-x-small from global;
  margin-right: auto;
}

.list {
  composes: reset-list from global;
  display: flex;
  flex-direction: column;
  gap: var(--unit--vertical);
}

.events {
  margin-top: var(--unit--vertical);
}

.link {
  margin-top: calc(var(--unit--vertical) - (var(--lh) - var(--fs)));

  --pill--border-color--hover: none;
  --pill--border-color: none;
}
</style>
