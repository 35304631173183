<template>
  <MapboxDefaultMarker
    v-if="entry"
    :marker-id="`${entry?.mapType}-marker-${entry.id}`"
    :lnglat="{ lat: localizedData?.lat, lng: localizedData?.long }"
  >
    <template #marker>
      <MapMarkerWithPopupVenueButtonComponent
        v-if="type === 'venue'"
        :backgroundColor="backgroundColor"
        :type="type"
        :title="title"
        :showPill="showPill && !hideMarker"
        @click="openPopup = true"
        :eventCount="localizedData?.event_entries?.data?.length"
      />

      <MapMarkerWithPopupEventLocationButtonComponent
        v-else-if="type === 'event-location'"
        :backgroundColor="backgroundColor"
        :type="type"
        :title="title"
        :showPill="showPill && !hideMarker"
        @click="openPopup = true"
      />

      <MapMarkerWithPopupRouteButtonComponent
        v-else-if="type === 'route'"
        :backgroundColor="backgroundColor"
        :type="type"
        :title="title"
        :showPill="showPill && !hideMarker"
        @click="openPopup = true"
      />

      <MapMarkerWithPopupFocusWeekButtonComponent
        v-else-if="type === 'focus-week'"
        :backgroundColor="backgroundColor"
        :type="type"
        :title="title"
        :showPill="showPill && !hideMarker"
        @click="openPopup = true"
      />
    </template>

    <div
      :class="$style.popup"
      v-if="openPopup"
    >
      <MapboxDefaultPopup
        :popup-id="`${entry?.mapType}-popup-${entry.id}`"
        :lnglat="{lat: localizedData?.lat, lng: localizedData?.long}"
        :options="{
          closeButton: true,
          className: $style.popup,
          closeButton: false,
          anchor: 'center',
          focusAfterOpen: false,
        }"
        :class="$style.popup"
        @open="onOpenPopup"
        @close="onClosePopup"
      >
        <div
          :class="$style.popupContent"
          :style="backgroundColor ? `--background-color: ${backgroundColor};` : null"
        >
          <MapMarkerWithPopupEventComponent
            :entry="entry"
            v-if="type === 'event-location'"
          >
            <button
              @click="onClickToClosePopup"
              :class="$style.buttonToClosePopup"
            >
              <SvgoCross />
            </button>
          </MapMarkerWithPopupEventComponent>

          <MapMarkerWithPopupVenueComponent
            :entry="entry"
            v-else-if="type === 'venue'"
          >
            <button
              @click="onClickToClosePopup"
              :class="$style.buttonToClosePopup"
            >
              <SvgoCross />
            </button>
          </MapMarkerWithPopupVenueComponent>

          <MapMarkerWithPopupRouteComponent
            :entry="entry"
            v-else-if="type === 'route'"
          >
          <button
            @click="onClickToClosePopup"
            :class="$style.buttonToClosePopup"
          >
            <SvgoCross />
          </button>
          </MapMarkerWithPopupRouteComponent>

          <MapMarkerWithPopupFocusWeekComponent
            :entry="entry"
            v-else-if="type === 'focus-week'"
          >
            <button
              @click="onClickToClosePopup"
              :class="$style.buttonToClosePopup"
            >
              <SvgoCross />
            </button>
          </MapMarkerWithPopupFocusWeekComponent>
        </div>
      </MapboxDefaultPopup>
    </div>
  </MapboxDefaultMarker>
</template>

<script setup>
import { camelCase } from 'lodash-es'
const mapStore = useMapStore()

const props = defineProps({
  entry: {
    type: Object
  },
  zoom: {
    type: Number,
    required: false
  },
  flyTo: {
    type: Function,
    required: true
  }
})

const hideMarker = ref(false)
const openPopup = ref(false)
const type = props?.entry?.mapType
const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.entry))
})
const title = computed(() => i18nGetLocalizedTitle(localizedData?.value))

const backgroundColor = computed(() => {
  if (type === 'city') {
    return localizedData?.value?.cluster_entry?.data?.attributes?.background_color
  } else if (type === 'venue') {
    return localizedData?.value?.city_entry?.data?.attributes?.cluster_entry?.data?.attributes?.background_color
  } else if (type === 'event-location') {
    return localizedData?.value?.cluster_entry?.data?.attributes?.background_color
  } else if (type === 'cluster') {
    return localizedData?.value?.background_color
  } else if (type === 'route') {
    return 'var(--color--parcour)'
  } else if (type === 'focus-week') {
    return localizedData?.value?.city_entry?.data?.attributes?.cluster_entry?.data?.attributes?.background_color
  } else {
    return false
  }
})

const showPill = computed(() => {
  const typeMatch = camelCase(type)
  const match = mapStore?.showPill[typeMatch]
  return props?.zoom >= match
})

const popup = useMapboxPopupRef(`${props?.entry?.mapType}-popup-${props?.entry.id}`);

const onClickToClosePopup = () => {
  popup.value?.remove()
}

const emit = defineEmits(['onOpenPopup', 'onClosePopup'])
const onOpenPopup = () => {
  hideMarker.value = true
  if (type === 'venue') {
    props?.flyTo(localizedData.value?.long, localizedData.value?.lat, mapStore?.zoom?.venue)
  } else if (type === 'event-location') {
    props?.flyTo(localizedData.value?.long, localizedData.value?.lat, mapStore?.zoom?.eventLocation)
  }  else if (type === 'route') {
    props?.flyTo(localizedData.value?.long, localizedData.value?.lat, mapStore?.zoom?.route)
  } else if (type === 'focus-week') {
    props?.flyTo(localizedData.value?.long, localizedData.value?.lat, mapStore?.zoom?.focusWeek)
  }

  emit('onOpenPopup')
}

const onClosePopup = () => {
  hideMarker.value = false
  emit('onClosePopup')
}
</script>

<style module>
.root {
}

.popup {
  composes: reset-scrollbar from global;

  --max-width: 20rem;

  max-width: 100% !important;
  width: var(--max-width) !important;
  z-index: var(--z-index--popup);
}

.popup :global(.mapboxgl-popup-content) {
  margin: 0;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
}

.popup :global(.mapboxgl-popup-tip) {
  display: none;
}

.popupContent {
  composes: font-size-x-small reset-scrollbar font-default from global;

  max-height: calc(var(--map--height) - var(--unit--vertical) * 2);
  overflow: scroll;

  background-color: var(--background-color, var(--color--blue));
  padding: var(--unit--vertical) var(--unit--horizontal);
  border-radius: var(--border-radius--card);

  box-shadow: var(--map--marker-with-popup--box-shadow);
}

.buttonToClosePopup {
  composes: reset-button from global;
  width: 1.5ch;
  display: block;
  margin-left: auto;
}

.buttonToClosePopup svg {
  width: 100%;
  stroke: currentColor;
  stroke-width: var(--stroke--default);
}
</style>
